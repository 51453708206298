<template>
  <a-card>
    <template v-slot:title>
      <div>Edit Profile</div>
      <div class="card-subtitle">Change or reset your account password</div>
    </template>
    <div class="editor-container">
      <p class="editor-head">Username</p>
      <a-input disabled :default-value="userName" />
      <p class="editor-head">Email</p>
      <a-input v-model:value="newEmailInput" :default-value="userEmail" />
      <a-button type="primary" @click="onChangeProfile">Save Change</a-button>
    </div>
    <a-modal v-model:visible="modalVisible" class="pass-modal" title="Email Changed" @ok="modalOk">
      <div class="floating-title-icon"><FeatherIcon type="check-circle" :size="16" /></div>
      <p>Your email has been changed successfully</p>
    </a-modal>
  </a-card>
</template>

<script>
import { ref, toRef, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';

const EditProfile = {
  name: 'EditProfile',
  props: {
    userName: String,
    accountId: String,
    userId: String,
  },
  setup(props) {
    let modalVisible = ref(false);
    let accountId = toRef(props, 'accountId');
    let userId = toRef(props, 'userId');
    let newEmailInput = ref('');
    const store = useStore();
    const userEmail = computed(() => store.getters['account/userEmail']);

    onMounted(() => {
      newEmailInput.value = userEmail.value;
    });

    const onChangeProfile = async () => {
      console.log('save change');
      let data = { role: 'admin', email: newEmailInput.value };
      const options = { type: 'dom', 'account-id': accountId.value, id: userId.value };

      const result = await api.updateUser(data, options).catch((e) => {
        console.log(e);
      });
      if (result) {
        console.log(result);
        store.dispatch('account/setEmail', newEmailInput);
        modalVisible.value = true;
      }
    };

    const modalOk = () => {
      modalVisible.value = false;
    };

    return {
      onChangeProfile,
      userEmail,
      newEmailInput,
      modalVisible,
      modalOk,
    };
  },
};
export default EditProfile;
</script>
