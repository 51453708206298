<template>
  <a-card>
    <template v-slot:title>
      <div>Notification Settings</div>
      <div class="card-subtitle">The selected notification type will be sent to your email</div>
    </template>
    <div class="editor-container-wide">
      <p class="editor-head">
        Notification Type
        <FeatherIcon type="alert-circle" :size="18" :color="'#ffffff'" :fill="'#9299b8'" />
      </p>
      <p class="float-right" @click="selectAll">Select All</p>
      <div class="group-toggle">
        <div>
          <p>Receive weekly and monthly <strong>summary report</strong></p>
          <a-switch v-model:checked="newAcceptReport" @change="onReportCheck" />
        </div>
        <div>
          <p>Notify me when <strong>alert keyword</strong> is detected</p>
          <a-switch v-model:checked="newAcceptSpecialKeyword" @change="onSpecialKeywordCheck" />
        </div>
        <div>
          <p>Notify me when <strong>DOM setting is updated</strong></p>
          <a-switch v-model:checked="newAcceptSettingChange" @change="onSettingChangeCheck" />
        </div>
        <!-- <div>
          <p>Notify me when system detects <strong>abnormal activity</strong></p>
          <a-switch v-model:checked="newAcceptAnomaly" @change="onAnomalyCheck" />
        </div>
        <div>
          <p>Notify me when <strong>data usage is nearing the limit</strong><span style="color: red"> *mockup</span></p>
          <a-switch v-model:checked="checked" />
        </div> -->
      </div>
      <a-button type="primary" @click="onChangeNoti">Update Email Notifications</a-button>
    </div>
    <a-modal v-model:visible="modalVisible" class="pass-modal" title="Settings Changed" @ok="modalOk">
      <div class="floating-title-icon"><FeatherIcon type="check-circle" :size="16" /></div>
      <p>Your notification settings has been changed successfully</p>
    </a-modal>
  </a-card>
</template>

<script>
import { ref, toRef, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';

const NotificationSettings = {
  name: 'NotificationSettings',
  props: {
    accountId: String,
    userId: String,
  },
  setup(props) {
    let checked = ref(false);
    let modalVisible = ref(false);
    let accountId = toRef(props, 'accountId');
    let userId = toRef(props, 'userId');

    let newAcceptReport = ref(false);
    let newAcceptSpecialKeyword = ref(false);
    let newAcceptAnomaly = ref(false);
    let newAcceptSettingChange = ref(false);

    const store = useStore();
    const userNotificationSettings = computed(() => store.getters['account/userNotificationSettings']);

    onMounted(() => {
      newAcceptReport.value = userNotificationSettings.value.isAcceptReport;
      newAcceptSpecialKeyword.value = userNotificationSettings.value.isAcceptSpecialKeyword;
      newAcceptAnomaly.value = userNotificationSettings.value.isAcceptAnomaly;
      newAcceptSettingChange.value = userNotificationSettings.value.isAcceptSettingChange;
    });

    const selectAll = () => {
      newAcceptReport.value = true;
      newAcceptSpecialKeyword.value = true;
      newAcceptAnomaly.value = true;
      newAcceptSettingChange.value = true;
    };
    const onReportCheck = (e) => {
      console.log(e);
      newAcceptReport.value = e;
    };
    const onSpecialKeywordCheck = (e) => {
      newAcceptSpecialKeyword.value = e;
    };
    const onAnomalyCheck = (e) => {
      newAcceptAnomaly.value = e;
    };
    const onSettingChangeCheck = (e) => {
      newAcceptSettingChange.value = e;
    };

    const onChangeNoti = async () => {
      let data = {
        role: 'admin',
        isAcceptReport: newAcceptReport.value,
        isAcceptSpecialKeyword: newAcceptSpecialKeyword.value,
        isAcceptAnomaly: newAcceptAnomaly.value,
        isAcceptSettingChange: newAcceptSettingChange.value,
      };
      const options = { type: 'dom', 'account-id': accountId.value, id: userId.value };
      console.log('save change');
      console.log(data, options);
      const result = await api.updateUser(data, options).catch((e) => {
        console.log(e);
      });
      if (result) {
        console.log(result);
        store.dispatch('account/setNotifications', data);
        modalVisible.value = true;
      }
    };

    const modalOk = () => {
      modalVisible.value = false;
    };

    return {
      userNotificationSettings,
      newAcceptReport,
      newAcceptSpecialKeyword,
      newAcceptAnomaly,
      newAcceptSettingChange,
      selectAll,
      onChangeNoti,
      onReportCheck,
      onSpecialKeywordCheck,
      onAnomalyCheck,
      onSettingChangeCheck,
      modalVisible,
      modalOk,
      checked,
    };
  },
};
export default NotificationSettings;
</script>
